import {Button, Checkbox, Form, Input, InputNumber, Select, Switch, Tooltip,} from 'antd';
import styles from './UsersForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';
import { usersApi } from '../usersApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import {useIntl} from "react-intl";
import { useNavigate } from 'react-router-dom';
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {QuestionCircleOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {isLoginAsAble} from "../../../utils/isLoginAsAble";
import {useHandleLogout} from "../../../hooks/useHandleLogout";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

export const UsersForm = ({ apiUser, isDisabled, onFinish, refetch }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [timezonesList, setTimezonesList] = useState([]);
  const [isOtpEnabled, setIsOtpEnabled] = useState(false);
  const [isAdminDepVisible, setIsAdminDepVisible] = useState(false);
  const [tagsValue, setTagsValue] = useState([]);
  const [timezone, setTimezone] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const navigate = useNavigate();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [viewStartTimeValue, setViewStartTimeValue] = useState(null);
  const [viewStartTimeUnit, setViewStartTimeUnit] = useState("days");
  const [isDisabledStartTime, setIsDisabledStartTime] = useState(true);
  useEffect(() => {
    if (user && apiUser) {
      if (!user.isAdmin && (apiUser.isAdmin || apiUser.id === user.id)) {
        navigate('/no-permissions');
      }
      setTimezone(apiUser.timezone);
      form.setFieldsValue(apiUser);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(apiUser.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(apiUser.updatedAt, user.timezone)
      );
      setIsActive(apiUser.isActive);
      setIsAdmin(apiUser.isAdmin);
      setIsAdminDepVisible(apiUser.isAdminDepVisible);
      setIsOtpEnabled(apiUser.otpEnabled)
      const arrTags = []
      if(apiUser.isCanChangeIntegration) arrTags.push('isCanChangeIntegration')
      if(apiUser.transhipment) arrTags.push('transhipment')
      if(apiUser.isCanInject) arrTags.push('isCanInject')
      if(apiUser.isPaymentAllowed) arrTags.push('isPaymentAllowed')
      if(apiUser.isFakeReportAvailable) arrTags.push('isFakeReportAvailable')
      if(apiUser.isCanSetFakeInfo) arrTags.push('isCanSetFakeInfo')
      if(apiUser.isExportAvailable) arrTags.push('isExportAvailable')
      if(apiUser.isStatusChangeAllowed) arrTags.push('isStatusChangeAllowed')
      if(apiUser.isCanEditLead) arrTags.push('isCanEditLead')
      if(apiUser.isCanChangeBoxId) arrTags.push('isCanChangeBoxId')
      if(apiUser.canChangeServerDns) arrTags.push('canChangeServerDns')

        if(apiUser?.viewStartTime) {
            const parsedViewStartTime = JSON.parse(apiUser.viewStartTime)
            setViewStartTimeValue(parsedViewStartTime?.value || null);
            setViewStartTimeUnit(parsedViewStartTime?.unit || null);
            setIsDisabledStartTime(false)
        } else {
            // setViewStartTimeValue(null);
            // setViewStartTimeUnit(null);
            setIsDisabledStartTime(true)
        }

      setTagsValue(arrTags)
    }
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
  }, [user, apiUser]);

  const fetchTeams = async (name) => {
    const res = await api.get(
      `/team?filter.name=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchTeams = async () => {
    const res = await api.get(`/team?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchPermissions = async (name) => {
    const res = await api.get(
      `/permissions?filter.name=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchPermissions = async () => {
    const res = await api.get(`/permissions?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const onIsAdmin = (e) => {
    setIsAdmin(e.target.checked);
  };

  const onSetIsAdminDepVisibleLead = (e) => {
    setIsAdminDepVisible(e.target.checked);
  };
  const onOtpEnabled = (e) => {
    setIsOtpEnabled(e.target.checked)
  }

  const handleChangeTags = (value) => {
    setTagsValue(value)
  }

  const tagsOptions = [
    {
      value: 'isCanInject',
      label: f({id: 'inject'}),
    },
    {
      value: 'transhipment',
      label: f({id: 'resend'}),
    },
    {
      value: 'isPaymentAllowed',
      label: f({id: 'payment_allowed'}),
    },
    {
      value: 'isFakeReportAvailable',
      label: f({id: 'fake_report_builder'}),
    },
    {
      value: 'isExportAvailable',
      label: f({id: 'export_column'}),
    },
    {
      value: 'isCanChangeIntegration',
      label: f({id: 'change_integration'}),
    },
    {
      value: 'isStatusChangeAllowed',
      label: f({id: 'change_status'}),
    },
    {
      value: 'isCanEditLead',
      label: f({id: 'edit_lead'}),
    },
      {
          value: 'canChangeServerDns',
          label: f({id: 'changeDns'}),
      },
    {
      value: 'isCanChangeBoxId',
      label: f({id: 'change_lead_box'}),
    },
  ]

    if(user?.isClientAllowedToSetFakeInfo) {
        tagsOptions.push({
            value: 'isCanSetFakeInfo',
            label: f({id: 'set_fake_lead_information'}),
        })
    }
  const isLoggedAs = !!localStorage.getItem('login_as')
  const [handleLogout] = useHandleLogout();
  const { editingKey } = useSelector((state) => state.defaultPage);
  const loginAs = (record) => {
    const type = apiUser.type === 'buyers' ? 'buyer' : 'user';
    api
        .post(`/auth/login-as/${type}/${record.id}`, {
          type: user.type,
          device: navigator.userAgent,
        })
        .then((res) => {
          handleLogout();
          localStorage.setItem('login_as', true);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('entry_type',  res.data.user_type);
          navigate('/');
          window.location.reload();
        });

    form.setFieldsValue(record);
  };

  const onChangeViewStartTimeInput = (value) => {
      setViewStartTimeValue(value);
  }

    const onChangeViewStartTimeSelect = (unit) => {
        setViewStartTimeUnit(unit);
    }

  return (
      <div>
     <span
         className="flex justify-between my-5"
     >
      {hasPermissions(user, PERMISSIONS.USERS_EDIT) && (
          <DeleteButton entity={apiUser} onFinish={onFinish}/>
      )}

      <div className="flex justify-between">
           {apiUser && <LogsInEntity drawerId={apiUser.id} entity='User'/>}
           { hasPermissions(user, PERMISSIONS.USERS_EDIT) && ( apiUser && !apiUser?.isAdmin && user.isAdmin) && (
               <span
                   className="flex justify-center"
               >
                <Tooltip
                    title={`${f({id: 'login_as'})} ${apiUser?.nickname}`}
                >
                  <Button
                      className="ml-2"
                      disabled={editingKey !== ''}
                      onClick={() => loginAs(apiUser)}
                      shape="circle"
                      icon={<UserSwitchOutlined/>}
                  />
                </Tooltip>
        </span>)
           }
      </div>
     </span>
          <Form
              form={form}
              onFinish={() => {
                  dispatch(usersApi.util.resetApiState());
                  onFinish({
                      ...form.getFieldsValue(),
                      isActive,
                      timezone,
                      isCanInject: !!tagsValue.find((e) => e === 'isCanInject'),
                      otpEnabled: isOtpEnabled,
                      role: 'NONE',
                      transhipment: !!tagsValue.find((e) => e === 'transhipment'),
                      isPaymentAllowed: !!tagsValue.find((e) => e === 'isPaymentAllowed'),
                      isCanChangeIntegration: !!tagsValue.find((e) => e === 'isCanChangeIntegration'),
                      isFakeReportAvailable: !!tagsValue.find((e) => e === 'isFakeReportAvailable'),
                      isCanSetFakeInfo: !!tagsValue.find((e) => e === 'isCanSetFakeInfo'),
                      isAdmin: user.isAdmin ? isAdmin : apiUser?.isAdmin || false,
                      isExportAvailable: !!tagsValue.find((e) => e === 'isExportAvailable'),
                      isStatusChangeAllowed: !!tagsValue.find((e) => e === 'isStatusChangeAllowed'),
                      isCanEditLead: !!tagsValue.find((e) => e === 'isCanEditLead'),
                      canChangeServerDns: !!tagsValue.find((e) => e === 'canChangeServerDns'),
                      isCanChangeBoxId: !!tagsValue.find((e) => e === 'isCanChangeBoxId'),
                      isAdminDepVisible: isAdminDepVisible,
                      teamId: form.getFieldValue('team')?.value,
                      permissionsId: form.getFieldValue('permissions')?.value,
                      viewStartTime: viewStartTimeValue && viewStartTimeUnit ?  JSON.stringify({value: viewStartTimeValue, unit: viewStartTimeUnit}) : null,
                      createdAt: undefined,
                      updatedAt: undefined,
                  });
              }}
              labelCol={{span: 10}}
              wrapperCol={{span: 14}}
          >
              <Form.Item
                  name="name"
                  label={f({id: 'name'})}
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Input placeholder={f({id: 'name'})} rootClassName={styles.input}/>
              </Form.Item>
              <Form.Item
                  name="nickname"
                  label={f({id: 'nickname'})}
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Input placeholder={f({id: 'nickname'})} rootClassName={styles.input}/>
              </Form.Item>
              <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                      {
                          required: true,
                          type: 'email',
                      },
                  ]}
              >
                  <Input placeholder="email" rootClassName={styles.input}/>
              </Form.Item>
              <Form.Item
                  rules={[
                      {
                          message: f({id: 'invalid_symbol'}, {s: "@"}),
                          validator: (_, value, callback) => {
                              let regex = /@/;
                              if (regex.test(value)) {
                                  callback('no error');
                              } else {
                                  callback();
                              }
                          },
                      },
                  ]}
                  name="telegram"
                  label="Telegram"
              >
                  <Input placeholder="Telegram" rootClassName={styles.input}/>
              </Form.Item>
              <Form.Item
                  name="password"
                  label={f({id: 'password'})}
                  rules={[
                      {
                          required: !apiUser,
                      },
                  ]}
              >
                  <Input placeholder={f({id: 'password'})} rootClassName={styles.input}/>
              </Form.Item>
              {!apiUser && (
                  <Form.Item
                      rules={[
                          {
                              required: true,
                          },
                      ]}
                      name="team"
                      label={f({id: 'team'})}
                  >
                      <AutocompleteSelect
                          className={styles.input}
                          value={selectedTeam}
                          placeholder={f({id: 'team'})}
                          initialFetch={initialFetchTeams}
                          fetch={fetchTeams}
                          onChange={setSelectedTeam}
                          allowClear
                      />
                      {selectedTeam?.value &&
                          <PermissionalButtonDrawer permissions={PERMISSIONS.TEAMS_EDIT} isShow={!!selectedTeam?.value}
                                                    onClick={() => {
                                                        setIsOpenDrawer(true)
                                                        setDataDrawer({id: selectedTeam?.value, type: 'teams'})
                                                    }}/>}
                  </Form.Item>
              )}
              {!apiUser && selectedTeam && (
                  <Form.Item
                      name="permissions"
                      label={f({id: 'rights_in_team'})}
                      rules={[
                          {
                              required: true,
                          },
                      ]}
                  >
                      <AutocompleteSelect
                          className={styles.input}
                          value={selectedPermissions}
                          placeholder={f({id: 'rights_in_team'})}
                          initialFetch={initialFetchPermissions}
                          fetch={fetchPermissions}
                          onChange={setSelectedPermissions}
                      />
                      {selectedPermissions?.value &&
                          <PermissionalButtonDrawer permissions={PERMISSIONS.PERMISSIONS_EDIT}
                                                    isShow={!!selectedPermissions?.value}
                                                    onClick={() => {
                                                        setIsOpenDrawer(true)
                                                        setDataDrawer({
                                                            id: selectedPermissions?.value,
                                                            type: 'permissions'
                                                        })
                                                    }}/>}
                  </Form.Item>
              )}
              <Form.Item
                  name="timezone"
                  label={f({id: 'timezone'})}
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Select
                      placeholder={f({id: 'timezone'})}
                      style={{maxWidth: 350}}
                      showSearch
                      defaultValue={timezone}
                      onChange={setTimezone}
                      options={timezonesList.map((t) => ({
                          value: t.timezone,
                          label: t.name,
                          key: t.timezone,
                      }))}
                  />
              </Form.Item>
              <Form.Item label={f({id: 'accesses'})}>
                  <Select
                      style={{maxWidth: 350}}
                      mode="multiple"
                      onChange={handleChangeTags}
                      value={tagsValue}
                      options={tagsOptions}
                  />
              </Form.Item>
              <Form.Item tooltip={f({id: 'leads_visibility_restriction_tooltip'})} label={f({id: 'leads_visibility_restriction'})}>
                  <span className={styles.formItemWithSwicher}>
                      <div>
                          <InputNumber
                              type="number"
                              min={1}
                              disabled={isDisabledStartTime}
                              style={{
                                  width: 100,
                              }}
                              value={viewStartTimeValue || null}
                              onChange={onChangeViewStartTimeInput}
                          />
                          <Select
                              style={{
                                  width: 90,
                                  margin: '0 8px',
                              }}
                              disabled={isDisabledStartTime}
                              value={viewStartTimeUnit || undefined}
                              onChange={onChangeViewStartTimeSelect}
                          >
                            <Select.Option value="days">Дні</Select.Option>
                            <Select.Option value="weeks">Тижні</Select.Option>
                            <Select.Option value="months">Місяці</Select.Option>
                          </Select>
                          </div>
                      <div className={styles.switchWraper}>
                       <Switch defaultChecked={isDisabledStartTime}
                          onChange={(state) => {
                              setIsDisabledStartTime(!isDisabledStartTime)
                              setViewStartTimeUnit("days")
                              setViewStartTimeValue(null)
                          }}
                       />
                      </div>
                  </span>
              </Form.Item>
              {apiUser && (
                  <Form.Item name="createdAt" label={f({id: 'created'})}>
                      <Input
                          disabled
                          placeholder={f({id: 'created'})}
                          rootClassName={styles.input}
                      />
                  </Form.Item>
              )}
              {apiUser && (
                  <Form.Item name="updatedAt" label={f({id: 'updated'})}>
                      <Input
                          disabled
                          placeholder={f({id: 'updated'})}
                          rootClassName={styles.input}
                      />
                  </Form.Item>
              )}
              {user?.isAdmin && apiUser?.otpEnabled && (
                  <Form.Item name="otpEnabled" label={'2FA'}>
                      <Checkbox
                          onClick={onOtpEnabled}
                          defaultChecked={apiUser?.otpEnabled}
                          rootClassName={styles.input}
                      />
                  </Form.Item>
              )}
              {(user?.isAdmin && user?.isClientAllowedToSetFakeInfo) &&
                  <Form.Item name="isAdminDepVisible" label={f({id: 'is_admin_dep_visible'})}>
                      <Checkbox
                          onClick={onSetIsAdminDepVisibleLead}
                          defaultChecked={apiUser?.isAdminDepVisible}
                          rootClassName={styles.input}
                      />
                  </Form.Item>}
              {user?.isAdmin && (
                  <Form.Item name="isAdmin" label={f({id: 'admin'})}>
                      <Checkbox
                          onClick={onIsAdmin}
                          defaultChecked={apiUser?.isAdmin}
                          rootClassName={styles.input}
                      />
                  </Form.Item>
              )}
              <Form.Item wrapperCol={{offset: 11}}>
                  <Button
                      type="primary"
                      loading={isDisabled}
                      disabled={isDisabled}
                      htmlType="submit"
                  >
                      {f({id: 'save'})}
                  </Button>
              </Form.Item>
          </Form>
          <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer}/>
      </div>
  );
};
