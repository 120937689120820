import {
    Modal,
    Tabs,
    Tooltip,
} from 'antd';
  import styles from './Caps.module.css';
  import { useIntl } from "react-intl";
  import React from 'react';
import { useSelector } from 'react-redux';
import { DrawerCustom } from '../../../shared/Drawer/Drawer';
import { BoxOrders } from '../../Boxes/BoxOrders/BoxOrders';
import {
  useCreateOrderBuyerMutation,
  useDeleteOrderBuyerMutation, useLazyGetBuyerOrderQuery,
  useUpdateOrderBuyerMutation
} from '../../Buyers/buyersApi';
import {
  useCreateOrderBoxMutation,
  useDeleteOrderBoxMutation,
  useLazyGetBoxOrderQuery,
  useUpdateOrderBoxMutation
} from '../../Boxes/boxesApi';
  
  export const CapsTeamsModal = ({ isModalOpen, handleCancel, boxes = [], buyers = [], callCenterSchedule, setIsOpenDrawer, setDataDrawer, isOpenDrawer, dataDrawer }) => {
    const { formatMessage: f } = useIntl();
    const [updateOrderBuyer] = useUpdateOrderBuyerMutation();
    const [createOrderBuyer] = useCreateOrderBuyerMutation();
    const [deleteOrderBuyer] = useDeleteOrderBuyerMutation();
    const [triggerBuyerOrder] = useLazyGetBuyerOrderQuery();
    const [updateOrderBox] = useUpdateOrderBoxMutation();
    const [createOrderBox] = useCreateOrderBoxMutation();
    const [deleteOrderBox] = useDeleteOrderBoxMutation();
    const [triggerOrderBox] = useLazyGetBoxOrderQuery();
      const { crmSettings } = useSelector((state) => state.globalSlice);
      const items = [
          {
              label:<Tooltip title={f({id: 'boxes'})}> {f({id: 'boxes'})}</Tooltip>,
              key: 'boxes',
              children: <div>
                  {boxes.map(b => (
                      b.id ?
                          <div>
                              <BoxOrders boxId={b.id} box={b} createOrderBox={createOrderBox} deleteOrderBox={deleteOrderBox}
                                         updateOrderBox={updateOrderBox} fetchBoxOrders={triggerOrderBox}/>
                          </div> :
                          <h4>
                              {f({id: 'please_contact'})}: {b.user}
                          </h4>
                  ))}
              </div>
          },
          {
              label: <Tooltip title={buyers.length ? f({id: crmSettings.affiliate}) : f({id: 'no_orders_in_buyers'})}>{f({id: crmSettings.affiliate})} </Tooltip>,
              key: 'webmasters',
              disabled:!buyers.length,
              children: <div>
                  {buyers.map(b => (
                      b.id ?
                          <div>
                              <BoxOrders boxId={b.id} createOrderBox={createOrderBuyer}
                                         deleteOrderBox={deleteOrderBuyer}
                                         updateOrderBox={updateOrderBuyer} fetchBoxOrders={triggerBuyerOrder} box={b}/>
                          </div> :
                          <h4>
                              {f({id: 'please_contact'})}: {b.user}
                          </h4>
                  ))}
              </div>
          },
      ];

      return (
          <>

              <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer}/>
              <Modal
                  className={styles.teamsModal}
                  open={isModalOpen}
                  footer={null}
                  onCancel={handleCancel}
                  width={920}
              >
                  <Tabs size='large'
                        type="card"
                        rootClassName='some-class'
                        items={items}
                        defaultActiveKey={'boxes'} />
              </Modal>
          </>

    );
  };
  
