import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import {FormattedMessage, useIntl} from "react-intl";

export const TeamInput = ({ defaultValue, onChange, disabled }) => {
  const { formatMessage: f } = useIntl();
  const fetchTeams = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/team?filter.name=$or:$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchTeams = async () => {
    const res = await api.get(`/team?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'team' })}
      initialFetch={initialFetchTeams}
      fetch={fetchTeams}
      onChange={(e) => onChange(e ? { id: e.value } : null)}
      defaultValue={{
        value: defaultValue?.id,
        label: `#${defaultValue.id} ${defaultValue.name}`,
      }}
      disabled={disabled}
      allowClear
    />
  );
};
