import React, { useState } from 'react';
import { useIntl } from "react-intl";
import {Button, Form, Input, List, message, Modal, Select, Tooltip} from 'antd';
import {DeleteOutlined, ForkOutlined, SettingOutlined, ShareAltOutlined} from '@ant-design/icons';
import {useCreateConditionsMutation} from "../../ordersApi";

const ConditionParams = ({ allConditions, refetch, entityId }) => {
    const { formatMessage: f } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ updateOrderConditions ] = useCreateConditionsMutation();
    const [conditions, setConditions] = useState(allConditions ? allConditions :  []);
    const onListCreate = (newCondition) => {
        if (newCondition.criteria === '**') {
            const existingCondition = allConditions.find(
                condition => condition.field === newCondition.field && (condition.criteria === '**' || condition.criteria === '===')
            );
            if (existingCondition) {
                message.error(f({ id: 'conditionExists' }));
                return;
            }
        } else if (newCondition.criteria === '===') {
            const existingCondition = allConditions.find(
                condition => condition.field === newCondition.field && (condition.criteria === '**' || condition.criteria === '===')
            );
            if (existingCondition) {
                message.error(f({ id: 'conditionExists' }));
                return;
            }
        }

        setIsLoading(true);
        const updatedConditions = [...allConditions, newCondition];
        setConditions(updatedConditions);
        updateOrderConditions({ conditions: updatedConditions, id: entityId })
            .finally(() => {
                refetch();
                message.success(f({id: 'conditionCreated'}));
                setIsLoading(false);
            });
    }


    const onDeleteCondition = (conditionToDelete) => {
        setIsLoading(true);
        const updatedConditions = allConditions.filter(condition => condition !== conditionToDelete);

        updateOrderConditions({conditions: updatedConditions, id: entityId})
            .then(() => {
                setConditions(updatedConditions);
                refetch();
                message.success(f({id: 'conditionDeleted'}));
            })
            .catch(() => {
                message.error(f({id: 'errorDeletingCondition'}));
            })
            .finally(() => setIsLoading(false));
    }

        const Footer = () => {
        const [isAdding, setIsAdding] = useState(false);
        const [selectedField, setSelectedField] = useState('sub1');
        const [selectedInput, setSelectedInput] = useState('');
        const [newValueField, setValueField] = useState('');
        const [selectedCriteria, setSelectedCriteria] = useState('**');

        return isAdding ?
            <div className="flex gap-3 items-center">

                <Select className="w-52" defaultValue="sub1"
                        placeholder={f({id: 'field'})}
                        onChange={value => setSelectedField(value)}>

                    <Select.Option value="sub1">Sub1</Select.Option>
                    <Select.Option value="sub2">Sub2</Select.Option>
                    <Select.Option value="sub3">Sub3</Select.Option>
                    <Select.Option value="lang">Language</Select.Option>
                    <Select.Option value="country">Country</Select.Option>
                    <Select.Option value="utmSource">Utm source</Select.Option>
                    <Select.Option value="utmCampaign">Utm campaign</Select.Option>
                    <Select.Option value="email">Email</Select.Option>

                </Select>
                <Select className="w-56" defaultValue="**"
                        placeholder={f({id: 'criteria'})}
                        onChange={value => setSelectedCriteria(value)}
                >
                    <Select.Option value="**"> {f({id:"**"})} </Select.Option>
                    <Select.Option value="==="> {f({id:"==="})} </Select.Option>
                    <Select.Option value="!=="> {f({id:"!=="})} </Select.Option>
                </Select>
                <Input  placeholder={f({id: 'Value'})} onBlur={value => setSelectedInput(value.target.value.trim())}  className="w-52" />
                {(selectedCriteria === '===' || selectedCriteria === '!==') && (<span>=</span>)}
                {(selectedCriteria === '===' || selectedCriteria === '!==') && (<Input placeholder={f({id: 'newValue'})} onBlur={value => setValueField(value.target.value.trim())} className="w-40" />)}
                <Button
                    className="ml-3"
                    type="primary"
                    onClick={() => {
                        onListCreate({ field: selectedField, criteria: selectedCriteria, userCriteria: selectedInput, newValueField });
                        setIsAdding(false);
                    }} >
                    {f({ id: 'save' })}
                </Button>
            </div> :
            <div><Button onClick={() => setIsAdding(true)}>{f({ id: 'add' })}</Button></div>
    }
    return (
        <>
            <Tooltip title={f({ id: 'conditials' })}><Button shape="round" className="ml-6" type="primary" icon={<ForkOutlined /> } onClick={() => setIsOpen(true)} /></Tooltip>
            <Modal
                closeIcon={null}
                open={isOpen}
                width={900}
                height={600}
                onCancel={() => setIsOpen(false)}
                footer={null}
            >
                <List
                    bordered
                    loading={isLoading}
                    footer={<Footer />}
                    dataSource={allConditions}
                    renderItem={(item) => (
                        <List.Item className="flex justify-between">
                            <div>{f({id:'iff'})} {f({id:'field'})} <span style={spanStyle}>{item.field}</span> {f({id:item.criteria})} <span style={inputStyle}>{item.userCriteria}</span> {item.newValueField && ( <> {f({id: 'changeTo'})} <span style={inputStyle}>{item.newValueField}</span> </> )}
                            </div>
                            <div>
                                <Button className="ml-2" shape="round" onClick={() => onDeleteCondition(item)} icon={<DeleteOutlined />} danger  />
                            </div>
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
};
export default ConditionParams;

const spanStyle={color:"#1677ff",textTransform:"capitalize",fontWeight:"bold"}
const inputStyle={color:"green",fontWeight:"bold"}