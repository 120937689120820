import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import { useIntl } from "react-intl";

export const PermissionInput = ({ defaultValue, onChange, disabled }) => {
  const { formatMessage: f } = useIntl();
  const fetchPermissions = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/permissions?filter.name=$or:$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchPermissions = async () => {
    const res = await api.get(`/permissions?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'permissions'})}
      initialFetch={initialFetchPermissions}
      fetch={fetchPermissions}
      onChange={(e) => onChange(e ? { id: e.value } : null)}
      defaultValue={{
        value: defaultValue?.id,
        label: `#${defaultValue.id} ${defaultValue.name}`,
      }}
      disabled={disabled}
      allowClear
    />
  );
};
