export const inputToFilter = (formValues, page, pageSize, isActive, selectedColumns = null) => {
  let res = '';
  if (formValues) {
    for (let i in formValues) {
      if(i === 'undefined') continue;
      if (getValueOnly(formValues[i]).length) {
        if(i === 'combinedTextFilter') {
          const splitValues = formValues[i].split(',')
          splitValues.forEach((splitValue) => {
            res += `filter.${i}=$or:$ilike:${splitValue}&`;
          })
        } else {
          res += `filter.${i}=$and:${formValues[i]}&`;
        }
      }
    }
  }
  const isActiveQuery =
    !formValues?.isActive && isActive ? '&filter.isActive=$and:$eq:true' : '';
  return selectedColumns 
        ? `${res}limit=${pageSize || 10}&page=${page}${isActiveQuery}&selectedColumns=${selectedColumns}` 
        : `${res}limit=${pageSize || 10}&page=${page}${isActiveQuery}`;
};

export const inputToFilterExport = (formValues) => {
  let res = '';
  for (let i in formValues) {
    if (getValueOnly(formValues[i]).length) {
      res += `filter.${i}=$and:${formValues[i]}&`;
    }
  }
  return res;
};

const getValueOnly = (value) => {
  return value.substr(value.indexOf(':') + 1);
};

export const sorterValue = (value) => {
  if (value?.order) {
    if (value?.order === 'descend') {
      return `&sortBy=${value.key}:DESC`;
    } else {
      return `&sortBy=${value.key}:ASC`;
    }
  }
  return '';
};
