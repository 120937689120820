import { Button, Checkbox, Form, Image, Input } from 'antd';
import styles from './OffersForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import TextArea from 'antd/es/input/TextArea';
import api from '../../../api/api';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import {offersApi, useLazyGetSharedByOfferQuery} from '../offersApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import ShareButton from "../../../shared/ShareButton/ShareButton";

export const OfferForm = ({ offer, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.globalSlice);
  const [isActive, setIsActive] = useState(
    offer?.isActive !== undefined ? offer?.isActive : true
  );
  const [timezonesList, setTimezonesList] = useState([]);
  const [trigger] = useLazyGetSharedByOfferQuery();
  const [shared, setShared] = useState();
  const [countries, setCountries] = useState();
  const [selectedOffer, setSelectedOffer] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (offer && user) { 
      form.setFieldsValue(offer);
      setCountries(offer.lang);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(offer.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(offer.updatedAt, user.timezone)
      );
      setIsActive(offer.isActive);
      if (offer?.groupOffer?.id) {
        setSelectedOffer({
          label: `#${offer.groupOffer.id} ${offer.groupOffer.name}`,
          value: offer.groupOffer.id,
          key: offer.groupOffer.id,
        });
        form.setFieldValue('groupOfferId', {
          label: `#${offer.groupOffer.id} ${offer.groupOffer.name}`,
          value: offer.groupOffer.id,
          key: offer.groupOffer.id,
        });
      }
      if (offer?.buyer?.id) {
        setSelectedUsers({
          label: `#${offer.buyer.id} ${offer.buyer.nickname}`,
          value: offer.buyer.id,
          key: offer.buyer.id,
        });
        form.setFieldValue('createdBy', {
          label: `#${offer.buyer.id} ${offer.buyer.nickname}`,
          value: offer.buyer.id,
          key: offer.buyer.id,
        });
      }
      if (offer?.user?.id) {
        setSelectedUser({
          label: `#${offer.user.id} ${offer.user.nickname}`,
          value: offer.user.id,
          key: offer.user.id,
        });
        form.setFieldValue('user', {
          label: `#${offer.user.id} ${offer.user.nickname}`,
          value: offer.user.id,
          key: offer.user.id,
        });
      }
    } else if (user) {
      setSelectedUser({
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
      form.setFieldValue('user', {
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
    }
  }, [offer, user]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const initialFetchGroups = async (name) => {
    const res = await api.get(`/group-offer/label?limit10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchGroups = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/group-offer/label?filter.name=$or:$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };


  const fetchUsers = async (name) => {
    const idFilter =
        !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
        `/user?filter.nickname=$or:$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';

    const res = await api.get(
      `/buyer/label?filter.nickname=$or:$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchBuyers = async () => {
    let teamFilter = '';

    const res = await api.get(
      `/buyer/label?limit=10&filter.isActive=$eq:true${teamFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('lang', value);
  };
  const refetchShared = () => {
    trigger(offer.id)
        .then(res => {
          setShared(res.data)
        })
  }
  useEffect(() => {
    if (!offer) form.setFieldValue('isActive', true);
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
    if (offer) {
      refetchShared();
    }
  }, []);
  return (
      <>
        <div className="flex justify-between">
          {hasPermissions(user, PERMISSIONS.OFFERS_EDIT) && (
              <DeleteButton entity={offer} onFinish={onFinish}/>
          )}
          {offer && <ShareButton shares={shared} entity={{offer_id: offer.id}} refetch={refetchShared} isSharingLeads/>}
        </div>
          <Form
              form={form}
              onFinish={() => {
                dispatch(offersApi.util.resetApiState());
                onFinish({
                  ...form.getFieldsValue(),
                  name: form.getFieldValue('name')?.trim(),
                  description: form.getFieldValue('description')?.trim(),
                  isActive: isActive ? isActive : false,
                  lang: countries,
                  user_id: selectedUser?.value,
                  file: selectedFile?.fileList?.[0]?.originFileObj,
                  groupOfferId: form.getFieldValue('groupOfferId').value,
                  createdBy: form.getFieldValue('createdBy').value,
                  createdAt: undefined,
                  updatedAt: undefined,
                });
              }}
              labelCol={{span: 10}}
              wrapperCol={{span: 14}}
          >
            <Form.Item
                name="name"
                label={f({id: 'title'})}
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <Input placeholder={f({id: 'title'})} rootClassName={styles.input}/>
            </Form.Item>
            <Form.Item name="description" label={f({id: 'description'})}>
              <TextArea
                  rows={4}
                  rootClassName={styles.input}
                  style={{resize: 'none', width: '350px'}}
                  placeholder={f({id: 'description'})}
              />
            </Form.Item>
            <Form.Item
                name="lang"
                label={f({id: 'country_one'})}
                rules={[
                  {
                    required: true,
                    max: 2,
                  },
                ]}
            >
              <CountrySelect onChange={onCountryChange} className={styles.input}/>
            </Form.Item>
            <Form.Item
                name="groupOfferId"
                label={f({id: 'group'})}
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <AutocompleteSelect
                  className={styles.input}
                  value={selectedOffer}
                  placeholder={f({id: 'group'})}
                  initialFetch={initialFetchGroups}
                  fetch={fetchGroups}
                  onChange={setSelectedOffer}
              />
              {selectedOffer?.value &&
                  <PermissionalButtonDrawer permissions={PERMISSIONS.OFFERS_EDIT} isShow={!!selectedOffer?.value}
                                            onClick={() => {
                                              setIsOpenDrawer(true)
                                              setDataDrawer({id: selectedOffer?.value, type: 'groups-offer'})
                                            }}/>}
            </Form.Item>
            {hasPermissions(user, PERMISSIONS.USERS_READ) && (
                <Form.Item
                    name="user"
                    label={f({ id: 'responsible'})}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                >
                  <AutocompleteSelect
                      className={styles.input}
                      value={selectedUser}
                      placeholder={f({ id: 'user' })}
                      initialFetch={initialFetchUsers}
                      fetch={fetchUsers}
                      onChange={setSelectedUser}
                  />
                  {selectedUser?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUser?.value}
                                                                    onClick={() => {
                                                                      setIsOpenDrawer(true)
                                                                      setDataDrawer({id: selectedUser?.value, type: 'users'})
                                                                    }}/>}
                </Form.Item>
            )}
            <Form.Item
                name="createdBy"
                label={f({id: 'author'})}
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <AutocompleteSelect
                  className={styles.input}
                  value={selectedUsers}
                  placeholder={f({id: 'author'})}
                  initialFetch={initialFetchBuyers}
                  fetch={fetchBuyers}
                  onChange={setSelectedUsers}
              />
              {selectedUsers?.value &&
                  <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUsers?.value}
                                            onClick={() => {
                                              setIsOpenDrawer(true)
                                              setDataDrawer({id: selectedUsers?.value, type: 'users'})
                                            }}/>}
            </Form.Item>
            <Form.Item
                name="file"
                style={{marginTop: 15, display: 'flex', justifyContent: 'center'}}
                rules={[
                  {
                    validator: (rule, value, callback, source, options) => {
                      if (!selectedFile && !offer)
                        callback(f({id: 'select_file_validate'}));
                      // if (selectedFile.fileList.length > 1) callback('Має бути вибрано один файл')
                      // console.log(selectedFile);
                      return callback();
                    },
                  },
                ]}
            >
              <Dragger
                  style={{width: '500px'}}
                  name="file"
                  onChange={(e) => setSelectedFile(e)}
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined/>
                </p>
                <p className="ant-upload-text">
                  {f({id: 'click_upload_file'})}
                </p>
              </Dragger>
            </Form.Item>
            {offer && (
                <Form.Item name="createdAt" label={f({id: 'created'})}>
                  <Input
                      disabled
                      placeholder={f({id: 'created'})}
                      rootClassName={styles.input}
                  />
                </Form.Item>
            )}
            {offer && (
                <Form.Item name="updatedAt" label={f({id: 'updated'})}>
                  <Input
                      disabled
                      placeholder={f({id: 'updated'})}
                      rootClassName={styles.input}
                  />
                </Form.Item>
            )}
            <Form.Item wrapperCol={{offset: 11}}>
              <Button
                  loading={isDisabled}
                  disabled={isDisabled}
                  type="primary"
                  htmlType="submit"
              >
                {f({id: 'save'})}
              </Button>
            </Form.Item>
            {offer?.preview && (
                <Form.Item
                    style={{marginTop: 15, display: 'flex', justifyContent: 'center'}}
                >
                  <div>
                    <Image width={500} src={`${offer?.preview}`}/>
                  </div>
                </Form.Item>
            )}
          </Form>
          <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer}/>
        </>
        );
        };
