import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import { useIntl } from "react-intl";

export const CustomerInput = ({ defaultValue, onChange }) => {
  const { formatMessage: f } = useIntl();
  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/customer/label?filter.name=$or:$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async (name) => {
    const res = await api.get(`/customer/label?limit10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'customer' })}
      initialFetch={initialFetchCustomers}
      fetch={fetchCustomers}
      onChange={(e) => onChange({ id: e.value })}
      defaultValue={{
        value: defaultValue.id,
        label: `#${defaultValue.id} ${defaultValue.name}`,
      }}
    />
  );
};
