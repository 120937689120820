import {Button, Form, Input, Tooltip} from 'antd';
import styles from './CustomersForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { customersApi, useLazyGetSharedByCustomerQuery } from '../customersApi';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import ShareButton from '../../../shared/ShareButton/ShareButton';
import {RightOutlined} from "@ant-design/icons";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

export const CustomersForm = ({ customer, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const { formatMessage: f } = useIntl();
  const [isActive, setIsActive] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const { user } = useSelector((state) => state.globalSlice);
  const [shared, setShared] = useState();
  const dispatch = useDispatch();
  const [trigger] = useLazyGetSharedByCustomerQuery();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});

  const refetchShared = () => {
    trigger(customer.id)
      .then(res => {
        setShared(res.data)
      })
  }

  useEffect(() => {
    if (customer) {
      refetchShared();
    }
  }, []);

  useEffect(() => {
    if (customer) {
      form.setFieldsValue(customer);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(customer.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(customer.updatedAt, user.timezone)
      );
      if (customer?.brand?.id) {
        form.setFieldValue('brand', {
          label: `#${customer.brand.id} ${customer.brand.name}`,
          value: customer.brand.id,
          key: customer.brand.id,
        });
        setSelectedBrand({
          label: `#${customer.brand.id} ${customer.brand.name}`,
          value: customer.brand.id,
          key: customer.brand.id,
        });
      }
      if (customer?.user?.id) {
        setSelectedUser({
          label: `#${customer.user.id} ${customer.user.nickname}`,
          value: customer.user.id,
          key: customer.user.id,
        });
        form.setFieldValue('user', {
          label: `#${customer.user.id} ${customer.user.nickname}`,
          value: customer.user.id,
          key: customer.user.id,
        });
      }
      setIsActive(customer.isActive);
    } else if (user) {
      setSelectedUser({
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
      form.setFieldValue('user', {
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
    }
  }, [customer, user]);

  const fetchBrands = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/brand/label?filter.name=$or:$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchBrands = async (name) => {
    const res = await api.get(`/brand/label?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.nickname=$or:$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };
  return (
    <>
      <div className="flex justify-between">
        <DeleteButton entity={customer} onFinish={onFinish} />
        <div className="flex">
          {customer && <LogsInEntity drawerId={customer.id} entity='Customer'/>}
          {customer && <ShareButton shares={shared} entity={{ customer_id: customer.id }} refetch={refetchShared} />}
        </div>
      </div>
      <Form
        form={form}
        onFinish={() => {
          dispatch(customersApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue('name'),
            isActive,
            brandId: selectedBrand?.value || null,
            createdAt: undefined,
            updatedAt: undefined,
            user_id: selectedUser?.value || form.getFieldValue('user').value,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="brand" label={f({id: 'sell_brand'})}>
          <AutocompleteSelect
            className={styles.input}
            value={selectedBrand}
            placeholder={f({id: 'sell_brand'})}
            initialFetch={initialFetchBrands}
            fetch={fetchBrands}
            allowClear={true}
            onChange={setSelectedBrand}
          />
          {selectedBrand?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.BRANDS_EDIT} isShow={!!selectedBrand?.value}
                                     onClick={() => {
                                       setIsOpenDrawer(true)
                                       setDataDrawer({id: selectedBrand?.value, type: 'brands'})
                                     }}/>}
        </Form.Item>
        {hasPermissions(user, PERMISSIONS.USERS_READ) && (
          <Form.Item
            name="user"
            label={f({ id: 'responsible'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={selectedUser}
              placeholder={f({ id: 'user' })}
              initialFetch={initialFetchUsers}
              fetch={fetchUsers}
              onChange={setSelectedUser}
            />
            {selectedUser?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUser?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedUser?.value, type: 'users'})
                                       }}/>}
          </Form.Item>
        )}
        <Form.Item name="apiKey" label="api-key">
          <Input
              disabled={true}
              placeholder="api-key"
              rootClassName={styles.input}
          />
        </Form.Item>
        {customer && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {customer && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};
