import { OrderedListOutlined } from "@ant-design/icons";
import { Button, List, Modal, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyGetBuyerPostbackLogsQuery } from "../buyersApi";
import { fromApiToClientDateTimePlusShift } from "../../../utils/dateFilters";

export const PostbackLogsModal = ({ buyerId, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [trigger] = useLazyGetBuyerPostbackLogsQuery();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isOpen) {
      trigger({ id: buyerId, page }).then((e) => {
        setData([...data, ...e.data[0]]);
        setCount(e.data[1]);
      });
    }
  }, [isOpen, page]);

  useEffect(() => {
    if (!isOpen) {
      setPage(1);
      setData([]);
    }
  }, [isOpen]);

  if (user.type === 'buyer') return;

  return (
    <div>
      <Tooltip title={f({ id: 'postback_logs_menu' })}>
        <Button shape="round" className="mr-6" type="primary" icon={<OrderedListOutlined />} onClick={() => setIsOpen(true)} />
      </Tooltip>
      <Modal
        title={f({ id: 'postback_logs_menu' })}
        onCancel={() => setIsOpen(false)}
        footer={null}
        open={isOpen}
      >
      <List
        size="small"
        bordered
        dataSource={data}
        className="flex justify-center flex-col"
        loadMore={data.length < count ? <div className="flex justify-center"><Button className="m-2 max-w-32" onClick={() => setPage(page+1)}>{f({ id: 'load_more' })}</Button></div> : undefined}
        renderItem={(item) => <List.Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{item.id}: {fromApiToClientDateTimePlusShift(item.createdAt)}</List.Item>}
      />
      </Modal>
    </div>
    
  );
};
