import { Button, Form, message, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import {FormattedMessage, useIntl} from "react-intl";

export const ShareEntityModal = ({
  rows,
  isModalOpen,
  handleCancel,
  onSuccess,
  shareEntity,
  isSharingLeads,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedScope, setSelecteScope] = useState('FULL');
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    form.setFieldValue('scope', 'FULL')
  }, []);

  const onStatusUpdate = () => {
    setIsLoading(true);
    shareEntity(
      rows.map((l) => ({
        id: l.id,
        userId: selectedUsers.value,
        scope: selectedScope,
      }))
    )
      .then(() => {
        message.success(f({ id: 'shared' }));
        onSuccess();
      })
      .catch((e) => message.error(e.response.data?.message))
      .finally(() => setIsLoading(false))

  };
  const fetchUsers = async (nickname) => {
    const idFilter =
      !isNaN(nickname) && nickname?.length
        ? `&filter.id=$or:$eq:${nickname}`
        : '';
    const res = await api.get(
      `/user/all?filter.nickname=$or:$ilike:${nickname}${idFilter}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
      active: c.isActive,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user/all?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
      active: c.isActive,
    }));
  };
  return (
    <Modal
      title={f({ id: 'share' })}
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onStatusUpdate}
      >
        <Form.Item
          name="user"
          label={f({ id: 'user' })}
          style={{ marginTop: 15 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            value={selectedUsers}
            placeholder={f({ id: 'user' })}
            initialFetch={initialFetchUsers}
            fetch={fetchUsers}
            onChange={setSelectedUsers}
          />
        </Form.Item>
        <Form.Item name="scope" label={f({ id: 'scope' })} rules={[
          {
            required: true,
          },
        ]}>
          <Select onChange={setSelecteScope} className="w-24" defaultValue="FULL">
            <Select.Option value="ID_ONLY">{f({ id: 'shared_scope_id_only' })}</Select.Option>
            <Select.Option value="FULL">{f({ id: 'shared_scope_full' })}</Select.Option>
            {isSharingLeads && <Select.Option value="FULL_LEADS">{f({ id: 'shared_scope_full_leads' })}</Select.Option>}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
          >
            {f({ id: 'share' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
