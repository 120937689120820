import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const buyersApi = createApi({
  reducerPath: 'buyersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getBuyers: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `buyer?${url}${sort}`;
      },
    }),
    getBuyer: builder.query({
      query: (id) => `buyer/${id}`,
    }),
    getBuyerPostbackLogs: builder.query({
      query: ({ id, page }) => `postback-log/${id}?page=${page}`,
    }),
    updateBuyer: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `buyer/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `buyer/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
    getSharedByBuyer: builder.query({
      query: (id) => `/sharing/entity/${id}?entity=buyer_id`,
    }),
    shareBuyerBatch: builder.mutation({
      query: (patch) => ({
        url: `/sharing/batch?entity=buyer_id`,
        method: 'PUT',
        body: patch,
      }),
    }),
    getBuyerOrder: builder.query({
      query: (id) => `/buyer-order/${id}`,
    }),
    updateOrderBuyer: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/buyer-order/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    createOrderBuyer: builder.mutation({
      query: (post) => ({
        url: `/buyer-order`,
        method: 'POST',
        body: post,
      }),
    }),
    deleteOrderBuyer: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/buyer-order/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  tagTypes: ['buyer'],
});

export const {
  useGetBuyersQuery,
  useGetBuyerQuery,
  useUpdateBuyerMutation,
  useLazyGetBuyerOrderQuery,
  useLazyGetSharedByBuyerQuery,
  useUpdateOrderBuyerMutation,
  useShareBuyerBatchMutation,
  useCreateOrderBuyerMutation,
  useDeleteOrderBuyerMutation,
  useLazyGetBuyersQuery,
  useLazyGetBuyerPostbackLogsQuery,
  useUpdateResponsibleMutation,
} = buyersApi;
